// extracted by mini-css-extract-plugin
export var container = "welcomeadvocacy-module--container--3PBXF";
export var content = "welcomeadvocacy-module--content--37b7h";
export var sideBar = "welcomeadvocacy-module--side-bar--3rd3y";
export var logoimage = "welcomeadvocacy-module--logoimage--333Yx";
export var navItem = "welcomeadvocacy-module--nav-item--20knw";
export var centrada = "welcomeadvocacy-module--centrada--3F_X7";
export var backgroundbio = "welcomeadvocacy-module--backgroundbio--2mWuv";
export var effectcolor = "welcomeadvocacy-module--effectcolor--2618V";
export var titleBlue = "welcomeadvocacy-module--titleBlue--1YkP7";
export var titleligthblue = "welcomeadvocacy-module--titleligthblue--1t4l5";
export var flexstart = "welcomeadvocacy-module--flexstart--2csd6";
export var alec_cover_1 = "welcomeadvocacy-module--alec_cover_1--1GYsT";
export var alec_image_1 = "welcomeadvocacy-module--alec_image_1--2PcIs";
export var imagen = "welcomeadvocacy-module--imagen--1UbOo";