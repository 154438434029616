import React, { useState } from 'react';


import WelcomeAdvocacy from '../components/index/Advocacy';
import Menu from '../components/menu/Menu';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 


const Advocacy = ()  => {

  return ( 
      <>  
		<Menu bgcolor='navbar navbar-expand-md navbar-dark fixed-top bg-blue'/>
		<WelcomeAdvocacy />
      </>
  )
} 

export default Advocacy;