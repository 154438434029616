
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import * as welcomeStyles from './welcomeadvocacy.module.scss';


 const Advocacy = () =>  {
  const [ref, isVisible] = useInView({ threshold: 0.6 });
  const controls = useAnimation();
// styles


  useEffect(() => {
    if(isVisible)
      controls.start('animate');
  }, [isVisible, controls])

  return (
  	<section ref={ref}  className={welcomeStyles.backgroundadvocacy}>
      <section ref={ref}  className={welcomeStyles.effectcolor}>
   	  <section ref={ref}  className={welcomeStyles.container}>
      
        
       <div className={welcomeStyles.content}>
         <div class="row">
            <div class="col-sx-12" className={welcomeStyles.flexstart}>
                <h1 class={welcomeStyles.titleBlue}>Advocacy</h1>
            </div>
         </div>
         <div class="row">
           <div class="col-md-6 col-sx-12">
                <p>The top photo on the right is of me with Chris Reeve at a rally in New York in 1995, on behalf of water protection.</p>
                <p>In the late 80’s and into the 90’s, I dedicated myself 
to the work of The Creative Coalition, an organization 
founded by Ron Silver, Chris, Blair Brown, and Susan 
Sarandon. TCC was funded, in part, by the generosity 
of then HBO CEO Michael Fuchs. Sharon O’Connell 
was its Executive Director.</p>
                <p>The group had an outstanding board and Ron Silver’s 
passion (and his insistence that TCC emerge as a group 
of entertainment figures who studied specific issues 
with dedication and knew the ins and outs of their core 
positions) produced many positive results. Taking the 
Amtrak to DC with Silver, Susan, Chris, et al and 
grilling one another on our pitch to lobby Congress on 
behalf of the National Endowment for the Arts was the 
beginning of a wonderful time in my advocacy work. At 
the time of his catastrophic accident, Chris was 
president of TCC. After his injury, I became president 
and was most gratified to oversee the group’s growth 
and increased effectiveness.</p>
                <p>TCC worked on behalf of reproductive rights for 
women, federal funding for the arts, gun control, 
campaign finance reform, and environmental 
protection.</p>
<p>The other photo is of me with Norman Lear and, obviously, Gregory Peck, taken at a fundraiser in Los Angeles. (To say that I was star struck to meet Peck, one of my greatest idols, is the epitome of understatement.) Norman had invited me to his home in 1988 for a fundraiser for then Democratic nominee Michael Dukakis. Soon afterward, Lear asked me to join the board of PEOPLE FOR THE AMERICAN WAY which I view as one of the honors of my lifetime. Peck had been a supporter of Lear’s organization.</p>
                 <p>What began in 1977 with an internship in the DC office 
of Congressman Jerome Ambro, who represented my 
home district on Long Island, to performing “constituent 
services” in the Santa Monica office of Tom Hayden, to 
lobbying on the Hill, meeting with President Clinton, to 
testifying before Congress, to seriously contemplating 
running for office myself, advocating on behalf of the 
issues I hold dear has been an essential part of my life. </p>
                <p>Has it hurt my career? Of course it has. Americans, for 
the most part, prefer their entertainment figures to 
remain apolitical or silent. I found that impossible.</p>
                <p>I am proudest of one fact: that I have never advocated 
on behalf of a position that I personally benefited from 
by way of lining my pockets. When an oil executive steps 
in front of the press to appeal for drilling on public lands 
or in environmentally sensitive areas, most Americans 
simply shrug and say, “Isn’t that their job?” However, 
corporate citizenship carries with it inviolable responsibilities. 
I have always maintained that wealthy corporations must be
held accountable for the exploitation that they practice. They 
must give back, in some measure, as they take.</p>
                <p>Politics in America is much changed since I walked into Hayden’s office and began helping people reduce their utility bills. The US government is uglier, less effective 
and more expensive. America is ridiculed around the world 
for its dated and offensive “exceptionalism.” However I am 
heartened by the fact that so many non-governmental 
groups are carrying a message of Hope Into Action. I list 
some of my favorites, as well as those I support in the arts, 
below. Please also visit my Foundation page on this site.</p>
               
<p className={welcomeStyles.titleligthblue}>Radiation and Public Health Project</p>
<p>Since the 1990’s, I have been involved with the work of various groups who are opposed to the use of nuclear energy by utility companies. One such group that I have worked very closely with is the Radiation and Public Health Project (RPHP). You can learn more about the work of RPHP and my friend Joe Mangano by visiting their site <a href="https://radiation.org/">here</a>.</p>
<p className={welcomeStyles.titleligthblue}>The Brennan Center for Justice</p>
<p>Back in the 1990’s, when I was involved with The Creative Coalition, I was introduced to Josh Rosenkranz, Burt Neuborne, and the work of the Brennan Center for Justice. The Brennan Center is a public policy institute located at NYU Law School. My passion for campaign finance reform was only heightened after spending time with the folks at Brennan whose knowledge of campaign finance laws, in my opinion, is unequaled. You can visit their website <a href="https://www.brennancenter.org/">here</a>.</p>
<p className={welcomeStyles.titleligthblue}>Southern Poverty Law Center</p>
<p>Morris Dees, along with Julian Bond and Joseph Levin Jr., formed the Southern Poverty Law Center in 1971. Their work in the field of legal advocacy is legendary and Dees himself is a great figure in the fight against institutional racial segregation and discrimination. You can visit their site <a href="https://www.splcenter.org/">here</a>.</p>
<p className={welcomeStyles.titleligthblue}>Planned Parenthood</p>
<p>The right of women to control their reproductive choices in this country is under a greater threat now then since the Roe v. Wade ruling. Abortion is one of the topics that has divided our country, our families, and ourselves, insofar as some individuals remain quite torn about the issue. I have concluded that it is for the woman to decide what path she will take and I am, therefore, pro-choice. My local Planned Parenthood (Planned Parenthood Hudson Peconic) is a group I have worked with regularly to maintain a woman’s right to choose. You can visit their site <a href="https://www.plannedparenthood.org/">here</a>.</p>
<p className={welcomeStyles.titleligthblue}>Gay Men’s Health Crisis</p>
<p>The battle against AIDS has tallied countless losses but also some victories. Today, those who are HIV positive have real hope of living normal lives due to the advances of both medicine and social awareness. Gay Men’s Health Crisis is a group I have supported for several years. You can visit their site <a href="https://www.gmhc.org/">here</a>. </p>
<p className={welcomeStyles.titleligthblue}>PETA</p>
<p>For over 30 years, I have supported various animal rights groups, the most well-known, perhaps, being People for the Ethical Treatment of Animals (PETA). Ingrid Newkirk, a great champion of all things pertaining to animal protection, is a mentor who has taught me so much. With Dan Mathews at her side, both Ingrid and PETA have worked to raise awareness of the incredible suffering that animals experience at the hands of human society. Visit PETA’s website <a href="https://www.peta.org/international/" >here</a>. Other animal rights groups I have supported are the Physician’s Committee for Responsible Medicine (PCRM). Their link is <a href="https://www.pcrm.org/">here</a>. And the Performing Animal Welfare Society (PAWS). You can find their website <a href="https://www.pawsweb.org/">here</a>.</p>
<p className={welcomeStyles.titleligthblue}>People for the American Way</p>
<p>People for the American Way was founded by legendary television writer/producer Norman Lear in 1981. They quickly moved to the forefront of liberal activism in monitoring and reporting on issues such as election integrity and the Supreme Court, to name just two. I have been on their board for several years and consider it one of the honors of my lifetime to work alongside these people. PFAW remains one of the most important resources for information regarding the health of our democracy. Their tireless work, highlighting the significance of both the Supreme Court’s members and decisions in our day-to-day lives, is among the most important I have ever participated in. You can find their link <a href="https://www.pfaw.org/">here</a>.</p>
           </div>
           <div class="col-md-6 col-sx-12">
           
           <img src="/static/Baldwin-Advocacy-fe98baac55e65c7e771517fd093de247.png" class={welcomeStyles.imagen} />   
              
           </div>
         </div>
       
        
      </div>
      </section>
      </section>
	</section>
	 
  )
}

export default Advocacy;
